/* eslint-disable */

/* eslint-disable class-methods-use-this */
import axios from 'axios';
import qs from 'qs';
import getLanguage from '../utils/getLanguage';
// import wrapPromise from './utils/wrapPromise';

const base_api = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
  base_api,
  headers: {
    'Content-Type': 'application/json',
  },
});

// TODO extra actions config
// TODO wrapPromise condition
const fetchRequest = async (endpoint, id, param, content = '', config = { qs: false, contentType: 'application/json' }) => {
  const params = {
    ...param,
    language: 1,
  };
  params.language = getLanguage()?.id || 1;
  if (content) {
    params.content = content;
  }

  let url = `${base_api}${endpoint}`;
  if (id) {
    url = `${base_api}${endpoint}/${id}`;
  }
  const apiConfig = {
    params,
    headers: { 'Content-Type': config.contentType },
  };
  if (config.qs) {
    // param qs should be an object example qs= { arrayFormat: 'repeat' }
    apiConfig.paramsSerializer = () => qs.stringify(params, { arrayFormat: 'repeat' });
  }
  const res = await axios.get(url, apiConfig);
  return res;
};

export default class GlobalApi {
  constructor() {
    this.lang = localStorage.getItem('language');

    this.getMenus = this.getMenus.bind(this);
    this.getArticle = this.getArticle.bind(this);
    this.getArticleDetail = this.getArticleDetail.bind(this);
    this.getJobDetail = this.getJobDetail.bind(this);
    this.getArticleDetailNew = this.getArticleDetailNew.bind(this);
    this.getArticleDetailByYearDescendingPagination = this.getArticleDetailByYearDescendingPagination.bind(this);
    this.getArticleDetailByYearDescendingPaginationLang = this.getArticleDetailByYearDescendingPaginationLang.bind(this);
    this.addReport = this.addReport.bind(this);
    this.getJobPosters = this.getJobPosters.bind(this);
    this.getArticleAllLangFalse = this.getArticleAllLangFalse.bind(this);
    this.getJobType = this.getJobType.bind(this);
    this.getEducation = this.getEducation.bind(this);
    this.getEmployee = this.getEmployee.bind(this);
    this.getUnit = this.getUnit.bind(this);
    this.getDepartment = this.getDepartment.bind(this);
    this.getGroupArticle = this.getGroupArticle.bind(this);
    this.getWidget = this.getWidget.bind(this);
  }

  getArticleAllLangFalse(param) {
    const params = param;
    const url = `?widgets=${params.widgets}& max_size=${params.max_size}& ordering=${params.ordering}&all_languages=False`;
    return fetchRequest('articles/list_details', url);
  }

  getMenus(id, param, content = '') {
    return fetchRequest('menus', id, param, content);
  }
  getUnit() {
    return fetchRequest('accounting', 'business_units/list_names/?client=22&max_size=true');
  }
  getDepartment(id) {
    return fetchRequest('employee-management', `departments/?business_unit=${id}&max_size=true`);
  }

  getEmployee(param) {
    const params = param;
    const url = `employees/list_names/?client=22&max_size=true&current_department=${params.department}&current_business_unit=${params.unit}`;
    return fetchRequest('employee-management', url);
  }

  async addReport(data) {
    let url = base_api + 'email_contents/send_sendgrid_email/';
    let newData = new FormData();
    for (let [key, val] of Object.entries(data)) {
      // console.log('key ', key)
      // console.log('val ', val)

      if (key === 'supporting_documents') {
        val.forEach((file, id) => newData.append(`supporting_documents[${id}]document`, file));
      } else if (key === 'reported_names') {
        val.forEach((name, id) => newData.append(`reported_names[${id}]`, name));
      } else {
        newData.append(key, val);
      }
    }

    // let newData = data;

    // if (data.supporting_documents) {
    //   newData = new FormData();

    //   // [data.reported_department].forEach((id) => newData.append('reported_department', id))
    //   [...data.supporting_documents].forEach((file, id) => newData.append(`supporting_documents[${id}]document`, file));
    //   [...data.reported_names].forEach((name, id) => newData.append(`reported_names[${id}]`, name));
    //   newData.append('supporting_documents', data.supporting_documents);
    //   newData.append('reported_department', data.reported_department);
    //   newData.append('allegation', data.allegation);
    //   // newData.append('reported_names', data.reported_names);
    // }

    // console.log('test newData ', [...newData])

    return await axios({
      method: 'post',
      url,
      data: newData,
      params: {},
    });
  }

  async sendEmail(data) {
    let url = base_api + "email_contents/send_sendgrid_email/";

    return await axios({
      method: "post",
      url,
      data,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },

      params: {},
    });
  }


  getArticle(param) {
    const params = param;
    const url = `?widgets=${params.widgets}&max_size=${params.max_size}&ordering=${params.ordering}`;
    return fetchRequest('articles/list_details', url);
  }

  getArticleDetailNew(param) {
    const params = param;
    const id = params.id
    const allLanguages = `?all_languages=${params.all_languages}`;
    const url = `${id}${allLanguages}`
    return fetchRequest('articles', url);
  }

  getArticleDetail(param) {
    const params = param;
    return fetchRequest('articles', params);
  }

  getGroupArticle(param) {
    const params = param;
    // console.log(params)
    return fetchRequest(
      'category_articles',
      `get_grouped_articles/?client=22${params.category}&all_languages=False&article_ordering=-date&article_status=Published&article_widgets=${params.widget}&article_limit=${params.limit}`
    );
  }

  getArticleDetailByYearDescendingPaginationLang(param, filter, size, search, page, year) {
    const params = param;
    const setfilter = `&categories=${filter || ''}`;
    const setSize = `?page_size=${size}`;
    const setSearch = `&search=${search}`;
    const setPage = `&page=${page}`;
    const setYear = `&date_year=${year}`;
    const order = '&ordering=-date';
    let url = null;
    const lang = localStorage.getItem('language');
    const status = '&status=Published';
    if (lang === 'English') {
      url = `${size ? setSize : `?page_size=5000`}${page ? setPage : ''}${year ? setYear : ''}${order}&widgets=${params}${filter ? setfilter : ''}${search ? setSearch : ''}${status || ''
        }&all_languages=False`;
    } else {
      url = `${size ? setSize : `?page_size=5000`}${page ? setPage : ''}${year ? setYear : ''}${order}&widgets=${params}${filter ? setfilter : ''}${search ? setSearch : ''}${status || ''
        }&all_languages=False`;
    }
    return fetchRequest('articles/list_details', url);
  }

  getArticleDetailByYearDescendingPagination(param, filter, size, search, page, year) {
    const params = param;
    const setfilter = `&categories=${filter || ''}`;
    const setSize = `?page_size=${size}`;
    const setSearch = `&search=${search}`;
    const setPage = `&page=${page}`;
    const setYear = `&date_year=${year}`;
    const order = '&ordering=-date';
    let url = null;
    const lang = localStorage.getItem('language');
    const status = '&status=Published';
    if (lang === 'English') {
      url = `${size ? setSize : `?page_size=5000`}${page ? setPage : ''}${year ? setYear : ''}${order}&widgets=${params}${filter ? setfilter : ''}${search ? setSearch : ''}${status || ''
        }&all_languages=False`;
    } else {
      url = `${size ? setSize : `?page_size=5000`}${page ? setPage : ''}${year ? setYear : ''}${order}&widgets=${params}${filter ? setfilter : ''}${search ? setSearch : ''}${status || ''
        }&all_languages=False`;
    }
    return fetchRequest('articles/list_details', url);
  }

  getJobPosters(clientId, company, position, widget, currency, typeJob, search, page, education) {
    console.log(page);
    return fetchRequest(
      'job_posters',
      `?article__client=${clientId}&page_size=6&company=${company}&position=${position}&widget=${widget}&currency=${currency}&job_type=${typeJob}&search=${search}&page=${page}&education=${education}&order=-article__date&status=Published`
    );
  }

  getJobDetail(param) {
    const params = param;
    return fetchRequest('job_posters', params);
  }

  getJobType() {
    return fetchRequest('job_types', `?max_size=true`);
  }

  getEducation(param) {
    const params = param;
    const url = `?client=${params.client}`;
    return fetchRequest('educations', url);
  }

  getWidget(id) {
    const url = `${id}`;
    return fetchRequest('widgets', url);
  }
}
