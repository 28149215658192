/* eslint-disable */

import { configureStore } from '@reduxjs/toolkit';
import contentSlice from './slices/contentSlice';
import TopicStore from './topicStore';

const store = configureStore({ 
  reducer: {
    content: contentSlice.reducer,
    TopicStore: TopicStore.reducer
  } 
});

export default store;
