// eslint-disable
import "@splidejs/splide/dist/css/splide.min.css";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useSelector } from "react-redux";
import Api from "./Api";

import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { BsFillSendFill } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import Swal from "sweetalert2";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import { EffectFade, Autoplay } from "swiper/modules";
import * as yup from "yup";
import GlobalApi from "../../context/api/GlobalApi";

const api = new Api();

export default function Silat_UnderConstruction() {
  const activeLanguage = useSelector((state) => state.content.activeLanguage);
  const [sections, setSections] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [logo, setLogo] = useState("");
  const [slider, setSlider] = useState([]);
  const [catalogue, setCatalogue] = useState(null);
  console.log(
    "🚀 ~ file: WB_UnderConstruction.jsx:30 ~ WB_UnderConstruction ~ catalogue:",
    catalogue
  );

  const [typograph, setTypograph] = useState([]);

  const [listButton, setListButton] = useState(null);

  const schema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required"),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const getSectionDetail = () => {
      api
        .getSectionDetail({ language: activeLanguage })
        .then((res) => {
          if (res === 200 || res.data) {
            setSections(res.data.results);
          }
        })
        .catch((err) => {
          console.log("~ err", err);
        });
    };

    getSectionDetail();
  }, [activeLanguage]);

  useEffect(() => {
    let switchCaseCompleted = 0;

    if (sections.length > 0) {
      const sec = sections[0]?.widgets;
      if (sec && sec.length !== 0) {
        sec.forEach((section, i) => {
          switch (i) {
            case 0: {
              setLogo(
                section?.widget_contents[0]?.file ||
                  section?.widget_contents[0]?.file
              );

              break;
            }
            case 1: {
              setTypograph(section?.widget_contents);

              break;
            }
            case 2: {
              setListButton(section?.widget_contents);
              break;
            }
            case 3: {
              setSlider(section?.widget_contents);
              break;
            }
            case 4: {
              setCatalogue(
                section?.widget_contents[0]?.file ||
                  section?.widget_contents[0]?.file
              );
              break;
            }
            default:
              break;
          }

          switchCaseCompleted++; // Increment the switch case completion counter
        });

        if (switchCaseCompleted === 5) {
          setLoaded(true); // Set loaded to true after all switch cases have completed
        }
      }
    }
  }, [sections]);

  const onSubmit = async (data) => {
    const api = new GlobalApi();
    const { email } = data;

    const mailData = {
      language: 1,
      email_data: {
        email: email,
      },
      email_content: 25,
    };

    try {
      await api.sendEmail(mailData);
      Swal.fire("", "success", "success");
      reset();
    } catch (err) {
      Swal.fire("", "error", "error");
    }
  };

  return loaded ? (
    <section className="">
      <div className="w-[100vw] relative h-[100vh] items-center justify-center grid lg:grid-cols-2 grid-cols-1">
        <div className="flex flex-col items-center justify-center lg:h-[100vh] h-full  w-full">
          <div className="hdl:px-0 px-[32px]">
            <div className="absolute  md:top-[50px] top-[30px] hdl:left-[80px] xl:left-auto md:left-[32px] rounded-full border-2 border-black md:p-3 p-2 ">
              <img
                src={logo}
                alt=""
                className="  md:w-[56px] md:h-[56px] h-[40px] w-[40px] "
              />
            </div>
            <div className=" max-w-[580px] hdxl:space-y-[34px] md::space-y-[28px] space-y-[16px] relative lg:mt-0 mt-[100px]">
              <div className="">
                <h3 className="hdxl:text-[90px] xs:text-[30px] s:text-[50px]  text-[#043170] font-[500]   text-center tracking-[0.4rem]">
                  {typograph[0]?.content}
                </h3>

                <div className="font-poppins md:text-[28px] text-[16px] text-[#043170]  text-center tracking-[0.8rem]">
                  {typograph[1]?.content}
                </div>
              </div>
              <div className="font-poppins md:text-[24px] text-[16px] text-[#ccc] text-center ">
                {typograph[2]?.content}
              </div>
              <div className="">
                <div className="font-poppins md:text-[24px] text-[16px] text-[#043170] text-center ">
                  {typograph[3]?.content}
                </div>
                <div className="font-poppins md:text-[24px] text-[16px] text-[#043170] text-center ">
                  {typograph[4]?.content}
                </div>
                <div className="font-poppins md:text-[24px] text-[16px] text-[#043170] text-center ">
                  {typograph[5]?.content}
                </div>
              </div>

              <div className="font-poppins md:text-[24px] text-[16px] text-[#ccc] text-center">
                {typograph[6]?.content}
              </div>

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="bg-white shadow md:h-[80px] h-[60px]  w-full items-center flex px-[24px] justify-between">
                  <div>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <input
                          className="outline-none md:w-[450px] w-[230px]"
                          type="text"
                          placeholder={"Email Address"}
                          {...field}
                        />
                      )}
                    />
                    {errors?.email && (
                      <p className="text-red-500 text-xs  py-1">
                        {errors?.email?.message}
                      </p>
                    )}
                  </div>
                  <button title="SEND" type="submit" className="cursor-pointer">
                    <BsFillSendFill className="h-[25px] w-[25px] hover:text-[#043170] font-bold" />
                  </button>
                </div>
              </form>
            </div>
            <div className="absolute  md:bottom-[50px] bottom-[30px] hdl:left-[80px] xl:left-auto  md:left-[32px]">
              <div className="flex space-x-[15px]">
                {/* <a
                  href="https://www.facebook.com/wira.budaya/"
                  target="_blank"
                  className=""
                >
                  <div className="rounded-full p-3 bg-[#ccc] hover:bg-black">
                    <FaFacebookF className="text-white text-[22px]" />
                  </div>
                </a> */}
              </div>
            </div>
          </div>
        </div>
        <div className="lg:-mt-0 s:-mt-16 -mt-8">
          <img
            src={slider[0]?.file}
            alt=""
            className="h-full w-full object-cover"
          />
        </div>
      </div>
    </section>
  ) : (
    <div className="w-[100vw] relative h-[100vh] items-center justify-center grid lg:grid-cols-2 grid-cols-1">
      <div className="">
        {" "}
        <div className="flex flex-col items-center justify-center md:h-[100vh] h-[calc(100vh)]  w-full">
          <div className="hdl:px-0 px-[32px]">
            <div className="absolute  md:top-[50px] top-[30px] hdl:left-[80px] xl:left-auto md:left-[32px] ">
              <Skeleton
                width={72}
                height={72}
                className="md:w-[72px] md:h-[72px]  w-[56px] h-[56px] "
              />
            </div>
            <div className=" max-w-[580px] hdxl:space-y-[34px] md::space-y-[28px] space-y-[16px] relative">
              <Skeleton width={300} count={7} />
            </div>
          </div>
        </div>
      </div>
      <Skeleton width={"100%"} className="!z-[100]  md:h-[100vh] h-[100vh]" />
    </div>
  );
}
